footer {
	.footerContainer {
		.questions {
			.subContainer {
				.row {
					p {
						img.garantee {
							max-width: 60px;
						}
					}
				}
			}
		}
	}
}